<template>
  <v-card
    tile
    flat
    :style="{ height: blockHeight + 'px', 'overflow-y': 'auto' }"
  >
    <v-card-title class="pb-0">
      <v-layout column>
        <h1 class="headline">
          Редактирование пользователя
        </h1>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="1"
          class="pa-0"
        >
          <v-text-field
            class="mt-4 ml-4"
            :value="user.id"
            label="Ид"
            :disabled="true"
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="pa-0 mt-n2"
        >
          <v-text-field
            v-model="user.contactPersonTitle"
            class="ma-2 pt-0"
            label="Ф.И.О."
            hide-details
            outlined
            dense
            readonly
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="pa-0 mt-n2"
        >
          <v-text-field
            v-model="user.name"
            class="ma-2 pt-0"
            label="Login"
            hide-details
            outlined
            dense
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="pa-0"
        >
          <v-text-field
            v-model="user.clientTitle"
            class="ma-2 pt-0"
            label="Название компании"
            hide-details
            outlined
            dense
            readonly
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="pa-0"
        >
          <v-text-field
            :value="role"
            class="ma-2 pt-0"
            label="Роль"
            hide-details
            outlined
            dense
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-btn
          class="ma-2"
          small
          @click="showPasswordForm = !showPasswordForm"
        >
          Изменить пароль
        </v-btn>
      </v-row>
      <v-row v-if="showPasswordForm">
        <v-col>
          <v-btn
            class="ma-2"
            small
            @click="generatePassword"
          >
            Сгенерировать пароль
          </v-btn>
        </v-col>
        <v-col>
          <span> Ваш новый пароль </span>
          <span class="font-weight-bold primary--text"> {{ generatedPassword }}</span>
        </v-col>
      </v-row>
      <v-row v-if="showPasswordForm">
        <v-col>
          <v-form
            class="ml-3 mt-1"
          >
            <v-row
              wrap
            >
              <v-col
                xs12
                md4
              >
                <v-text-field
                  v-model="newPassword"
                  class="mx-2 my-1"
                  label="Новый пароль"
                  outline
                  dense
                />
              </v-col>
              <v-col
                xs12
                md4
              >
                <v-text-field
                  v-model="confirmPassword"
                  class="mx-2 my-1"
                  label="Повторите новый пароль"
                  outline
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-btn
                :disabled="!valid"
                class="ml-2"
                small
                :loading="loading"
                @click="changePassword()"
              >
                Сохранить пароль
              </v-btn>
              <v-btn
                class="ml-2"
                small
                @click="cancel()"
              >
                Отмена
              </v-btn>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import auth from "../../auth.js";
import * as usersApi from "../../backend/usersApi.js";

export default {
  data: () => ({
    user: {},
    showPasswordForm: false,
    generatedPassword: "",
    confirmPassword: "",
    newPassword: "",
    loading: false,
  }),
  computed: {
    blockHeight() {
      return window.innerHeight - 165;
    },
    role() {
      return this.user.role === 0 ? "Администратор" : "Пользователь";
    },
    valid() {
      if (this.generatedPassword.length > 0 && this.newPassword === this.confirmPassword) {
        return this.newPassword === this.generatedPassword;
      }
      return false;

    },
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.user = auth.getUser();
    },
    async generatePassword() {
      this.generatedPassword = await usersApi.generatePassword();
    },
    async changePassword() {
      if (!this.newPassword || !this.confirmPassword) {
        return;
      }
      this.loading = true;
      await usersApi.changePassword(this.newPassword, this.confirmPassword);
      this.loading = false;
      this.cancel();

    },
    cancel() {
      this.showPasswordForm = false;
      this.generatedPassword = "";
      this.confirmPassword = "";
      this.newPassword = "";
    },
  },
};
</script>
